import styles from "./AccountList.module.css";
import { useStore } from "../../hooks/useStore";
import { Modal, Button, Form, Alert, InputGroup, Row, Col } from "react-bootstrap";
import { Dismiss12Regular } from "@fluentui/react-icons";
import { useState } from "react";
import showToasty from "../../hooks/useNotify";
import { EnterPrise } from "../../api";
import { convertVietnameseToEnglish, getCurrentEnterprise } from "@utils/string";
import { enterpriseIdLocal, setEnterPriseLocal } from "@utils/localStorage";
import { includes, isEmpty } from "lodash";
import { observer } from "mobx-react-lite";

const PRI_UNIT = [
    "Văn phòng Bộ - Bộ Khoa học và Công nghệ",
    "Cục Tần số vô tuyến điện - Bộ Khoa học và Công nghệ",
    "Cục Bưu điện Trung ương - Bộ Khoa học và Công nghệ",
    "Cục Chuyển đổi số quốc gia - Bộ Khoa học và Công nghệ",
    "Trung tâm Internet Viet Nam (VNNIC) - Bộ Khoa học và Công nghệ",
    "Viện Chiến lược Thông tin và Truyền thông - Bộ Khoa học và Công nghệ"
];

interface IProps {
    show: boolean;
    setShow: (s: boolean) => void;
    data: EnterPrise[];
}

export const AccountList = observer(({ show, setShow, data }: IProps) => {
    const {
        rootStore: { sessionChatsStore }
    } = useStore();

    const sufData = [...data];
    const preData: EnterPrise[] = [];

    PRI_UNIT.forEach(priName => {
        sufData.forEach((itemData, index) => {
            if (itemData.name === priName) {
                preData.push(itemData);
                sufData.splice(index, 1);
            }
        });
    });
    const formartData = preData.concat(sufData);

    const enterpriseId = enterpriseIdLocal();
    const [searchText, setSearchText] = useState<string>("");

    const onChangeSearch = (event: any) => {
        const input = event.target.value;
        setSearchText(input);
    };

    const handleCloseModel = () => {
        setSearchText("");
        setShow(false);
    };

    const onPressItem = (item: EnterPrise) => {
        setShow(false);
        showToasty("Thay đổi thành công", "success");
        setEnterPriseLocal(item?.id);
        localStorage.setItem("unit_name", `${item?.name}`);
        sessionChatsStore.SET_UNIT_NAME(item?.name);
    };

    const AcountItem = ({ item }: { item: EnterPrise }) => {
        const isSelected = enterpriseId === item?.id;
        if (isSelected) {
            sessionChatsStore.SET_UNIT_NAME(item?.name);
        }
        return (
            <li
                className={styles.accountItem}
                style={{ backgroundColor: isSelected ? "var(--bg-enterprise-active)" : "transparent" }}
                onClick={() => {
                    !isSelected && onPressItem(item);
                }}
                key={`${item?.id}`}
            >
                <div className={styles.accountAvatar}>
                    <div className="infor">
                        <span className="inforText">{getCurrentEnterprise(item?.name)}</span>
                    </div>
                </div>
                <span className={styles.listAccountItemText}>{item?.name}</span>
            </li>
        );
    };

    return (
        <Modal
            show={show}
            onHide={handleCloseModel}
            animation={false}
            aria-labelledby="contained-modal-title-center"
            className="modal-document-support modal-bootstrap"
            size="lg"
            scrollable
        >
            <Modal.Body>
                <div>
                    <div className={styles.listAccountHeader}>
                        <div className={styles.closeIconModelBox} onClick={handleCloseModel}>
                            <Dismiss12Regular style={{ color: "var(--text-primary-color)" }} />
                        </div>
                        <span className={styles.listAccountText}>Danh sách đơn vị </span>
                        <InputGroup className="me-4 mb-3 mt-3">
                            <Form.Control
                                type="text"
                                id="input-search-document-support"
                                className=""
                                placeholder="Nhập từ khóa tìm kiếm..."
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                autoComplete="off"
                            />
                        </InputGroup>
                    </div>
                    <Row>
                        {formartData
                            .filter(item => {
                                if (isEmpty(searchText)) {
                                    return true;
                                }
                                const itemVn2En = convertVietnameseToEnglish(item?.name).toLowerCase();
                                const searchVn2En = convertVietnameseToEnglish(searchText).toLowerCase();
                                return includes(itemVn2En, searchVn2En);
                            })
                            .map((item: EnterPrise, i: any) => (
                                <Col className={styles.listAccountsCol} key={i} xs={12} md={6}>
                                    <AcountItem item={item} />
                                </Col>
                            ))}
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
});
