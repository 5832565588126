import { isEmpty } from "lodash";

export const defaultHeader = (): any => {
    let token = localStorage.getItem("_token");
    if (token && !token.includes("Bearer")) {
        token = `Bearer ${token}`;
    }
    return {
        Authorization: token,
        "Type-Authorization": localStorage.getItem("_typeAuthorization"),
        "Refresh-Token": localStorage.getItem("_refresh_token")
    };
};
export const userIdLocal = (): any => {
    const userIdLocal = JSON.parse(localStorage.getItem("_user") || "{}");
    return userIdLocal?.userId;
};
export const enterpriseIdLocal = (): any => {
    let enterPriseLocal = localStorage.getItem("_enterpriseIdLocal");
    if (isEmpty(enterPriseLocal)) {
        const userIdLocal = JSON.parse(localStorage.getItem("_user") || "{}");
        enterPriseLocal = userIdLocal?.enterpriseId
    }
    
    return enterPriseLocal;
};
export const setEnterPriseLocal = (enterprise: string) => {
    localStorage.setItem("_enterpriseIdLocal", enterprise);
};
export const userNameLocal = (): any => {
    const userLocal = JSON.parse(localStorage.getItem("_user") || "{}");
    return userLocal?.fullname ? userLocal?.fullname : userLocal?.name ? userLocal?.name : "";
};
// export const userUnitLocal = (): any => {
//     const userLocal = JSON.parse(localStorage.getItem('_user') || '{}')
//     return userLocal?.fullname ? userLocal?.fullname : userLocal?.name ? userLocal?.name : ''
// }
export const nameAssistant = (): any => {
    const userLocal = JSON.parse(localStorage.getItem("_user") || "{}");
    return userLocal?.enterpriseName ? userLocal?.enterpriseName?.replace("- Bộ Khoa học và Công nghệ", "") : "";
};

export const unitNameAssistant = (): any => {
    const userLocal = localStorage.getItem("unit_name")
    return userLocal ? userLocal?.replace("- Bộ Khoa học và Công nghệ", "") : "";
};

export const subUnitNameAssistant = (): any => {
    const sentence = unitNameAssistant()
    if(sentence) {
        const words = sentence.split(' '); // Split the sentence into words based on spaces
        if (words.length >= 5) {
            return `${sentence.split(' ').slice(0, 4).join(' ')}...`;
          } else {
            return sentence
          }
    }
};

export const subCitation = (string: string, quantity: number): any => {
    const sentence = string
    if(sentence) {
        const words = sentence.split(' '); // Split the sentence into words based on spaces
        if (words.length >= quantity) {
            return `${sentence.split(' ').slice(0, quantity - 1).join(' ')}...`;
          } else {
            return sentence
          }
    }
};

export const textWelcome = () => {
    let greeting = 'Xin chào đồng chí ' + userNameLocal();
    
    if (nameAssistant() && nameAssistant().toLowerCase().includes('bộ Khoa học và Công nghệ') ) {
        greeting += ` - ${nameAssistant()}`;
    } else if (nameAssistant()) {
        greeting += ` - ${unitNameAssistant()} - Bộ Khoa học và Công nghệ`;
    }
    
    return greeting
}
